import * as React from "react";
import { Switch, Route } from "react-router-dom";
import * as loadable from 'react-loadable';
import Loading from "./components/loading/Loading";
import ProtectedRoute from "./components/protectedRoute/ProtectedRoute";
import { useSelector } from "react-redux";
import { AppState } from "./interfaces/general/App-State";
import { User } from "./interfaces/User";
import { Request } from "./interfaces/general/Request";

const AsyncLoginContainer = loadable({ loader: () => import(/* webpackChunkName: "Login" */ "./containers/authentication/Login"), loading: Loading });
const AsyncLogoutContainer = loadable({ loader: () => import(/* webpackChunkName: "Logout" */ "./containers/authentication/Logout"), loading: Loading });
const AsyncDashboardContainer = loadable({ loader: () => import(/* webpackChunkName: "Dashboard" */ "./containers/dashboard/Dashboard_v2"), loading: Loading });
const AsyncInterfacesContainer = loadable({ loader: () => import(/* webpackChunkName: "Interfaces" */ "./containers/interfaces/Interfaces"), loading: Loading });
const AsyncSyncHistoryContainer = loadable({ loader: () => import(/* webpackChunkName: "SyncHistory" */ "./containers/sync-history/SyncHistory"), loading: Loading });
const AsyncErrorLogContainer = loadable({ loader: () => import(/* webpackChunkName: "ErrorLog" */ "./containers/error-log/ErrorLog"), loading: Loading });
const AsyncLookUpTablesContainer = loadable({ loader: () => import(/* webpackChunkName: "LookUpTables" */ "./containers/lookup-tables/LookUpTables"), loading: Loading });
const AsyncNotificationsContainer = loadable({ loader: () => import ("./containers/notifications/Notifications"), loading: Loading });
const AsyncNotificationsDetailContainer = loadable({ loader: () => import ("./containers/notifications/NotificationsDetail"), loading: Loading });
const AsyncUserManagementContainer = loadable({ loader: () => import(/* webpackChunkName: "UserManagement" */ "./containers/user-management/UserManagement"), loading: Loading });
const AsyncAddUserContainer = loadable({ loader: () => import(/* webpackChunkName: "AddUser" */ "./containers/user-management/AddUser"), loading: Loading });
const AsyncUpdateUserContainer = loadable({ loader: () => import(/* webpackChunkName: "UpdateUser" */ "./containers/user-management/UpdateUser"), loading: Loading });

const Routes: React.FC = () => {
    const requests = useSelector<AppState, Request[]>(state => state.requests);
    const [userRequestStatus, setUserRequestStatus] = React.useState("REQUEST");
    const user = useSelector<AppState, User>((state) => state.user);

    React.useEffect(() => {
        var userRequestString = requests.filter(x => x.type === "user/GET_CURRENT").length > 0 ? requests.filter(x => x.type === "user/GET_CURRENT")[0].status : "REQUEST";
        
        setUserRequestStatus(userRequestString);
    }, [requests]);

    return (
        <Switch>
            <Route exact path="/login/:url?/:key?" render={() => <AsyncLoginContainer />} />
            <Route exact path="/logout" render={() => <AsyncLogoutContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""} exact path="/" render={() => <AsyncDashboardContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""} exact path="/interfaces" render={() => <AsyncInterfacesContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""} exact path="/sync-history" render={() => <AsyncSyncHistoryContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""} exact path="/error-log" render={() => <AsyncErrorLogContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""} exact path="/lookup-tables" render={() => <AsyncLookUpTablesContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""} exact path="/notifications" render={() => <AsyncNotificationsContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""} exact path="/notifications/detail/:historyId/:notificationSubscriptionTypeId" render={() => <AsyncNotificationsDetailContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} isAdminOnly authed={user && user.id != "" && (user.role === "Administrator" || user.role === "Client-Administrator")} exact path="/user-management" render={() => <AsyncUserManagementContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} isAdminOnly authed={user && user.id != "" && (user.role === "Administrator" || user.role === "Client-Administrator")} exact path="/user-management/add" render={() => <AsyncAddUserContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} isAdminOnly authed={user && user.id != "" && (user.role === "Administrator" || user.role === "Client-Administrator")} exact path="/user-management/update/:id" render={() => <AsyncUpdateUserContainer />} />
        </Switch>
    );
}

export default Routes;